import React from "react";
import './Slider.scss';

export default function CustomPagination() {

    return (
        <>
            <div className='custom-pagination'>
                
            </div>
        </>
    )
}

